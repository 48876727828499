<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">操作提醒</template>
        <el-alert
            title="组合套餐显示在商品详情，是一种捆绑购买的优惠活动"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="商家可以选择发布组合套餐，同时可以设置套餐价格"
            type="warning"
            :closable="false">
        </el-alert>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel :span="24">
      <el-form class="button" :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="优惠券套餐名:">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入优惠券套餐名"
              size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
          <el-button style="margin-right: 10px" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">
            添加组合套餐
          </el-button>
        </el-form-item>
      </el-form>
      <el-radio-group style="margin-left: 20px" v-model="queryForm.state" @change="buttonTab">
        <el-radio-button type="button"
                         v-for="item in button_state"
                         :key="item.id"
                         :label="item.id"
        >
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="name"
          label="名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="price"
          label="套餐价">
      </el-table-column>
      <el-table-column
          align="center"
          prop="original_cost"
          label="市场价">
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterState"
          prop="state"
          label="状态">
        <template #default="{row}">
          <span v-if="row.state == 1">已上架</span>
          <span v-else>已下架</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.create_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiDeletePackages, apiGetPackageList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      activeName: '',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        state: '',
      },
      current: 0,
      button_state: [
        {
          id: '',
          name: '所有套餐'
        },
        {
          id: 1,
          name: '已上架'
        },
        {
          id: 0,
          name: '已下架'
        },
      ],
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.state = 1
      } else if (num === 0) {
        this.queryForm.state = 0
      } else {
        this.queryForm.state = ''
      }
      this.fetchData()
    },
    fieldSetting() {
      this.$router.push({
        path: 'fieldSetting',
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'combinationPackage/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'combinationPackage/revise',
          query: {
            id: '',
          },
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要操作当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeletePackages({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetPackageList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      /*const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      const { list } = data
      for (let i = 0; i < list.length; i++) {
        list[i]['state'] = list[i]['state'].toString()
      }
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++*/
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '已下架' : row.state == '1' ? '已上架' : '暂无'
    },
  }
}

</script>
<style>
.tab_button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-color: #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 9px 15px;
  font-size: 13px;
  border-radius: 0;
}

.tab_button1 {
  background: #409eff;
  color: #f0f0f0;
}

.button {
  margin-top: 20px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
